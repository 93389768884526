// extracted by mini-css-extract-plugin
export var partner = "index-partners-module--partner--Qyw3f";
export var partnerChentou = "index-partners-module--partner-chentou--MLrUs";
export var partnerChentouTech = "index-partners-module--partner-chentou-tech--a450j";
export var partnerChenxiang = "index-partners-module--partner-chenxiang--d0+uZ";
export var partnerFuda = "index-partners-module--partner-fuda--dcyIC";
export var partnerFuzhou = "index-partners-module--partner-fuzhou--9wC2k";
export var partnerFuzhouchentou = "index-partners-module--partner-fuzhouchentou--gZ1LS";
export var partnerHaixia = "index-partners-module--partner-haixia--Zru+B";
export var partnerHongkon = "index-partners-module--partner-hongkon--O2OCS";
export var partnerJiangxi = "index-partners-module--partner-jiangxi--+FRa0";
export var partnerJianzhu = "index-partners-module--partner-jianzhu--ZtH1J";
export var partnerTieJian = "index-partners-module--partnerTieJian--phqsV";
export var partnerTiejan = "index-partners-module--partner-tiejan--FQ5zG";
export var partnerTongji = "index-partners-module--partner-tongji--EV-+H";
export var partnerTsinghua = "index-partners-module--partner-Tsinghua--Ip-BY";
export var partnerTumu = "index-partners-module--partner-tumu--QRj8T";
export var partnerXinjiang = "index-partners-module--partner-xinjiang--xVhqJ";
export var partnerZhongjiao = "index-partners-module--partner-zhongjiao--kU19k";
export var partnerZhongnan = "index-partners-module--partner-zhongnan--XNPpx";
export var partnerZhongtie = "index-partners-module--partner-zhongtie--APj7f";
export var partnerZhongye = "index-partners-module--partner-zhongye--6+c-M";
export var partnerZuohai = "index-partners-module--partner-zuohai--jtGLv";
export var partners = "index-partners-module--partners--taTe4";