// extracted by mini-css-extract-plugin
export var cover = "index-thumbnail-module--cover--pRjz8";
export var figureCaption = "index-thumbnail-module--figureCaption--Yn2-Y";
export var figureCaptionLevel1 = "index-thumbnail-module--figureCaptionLevel1--duFlz";
export var figureCaptionLevel2 = "index-thumbnail-module--figureCaptionLevel2---BTK7";
export var figureCaptionLevel3 = "index-thumbnail-module--figureCaptionLevel3--Av8KR";
export var figureCaptionWrap = "index-thumbnail-module--figureCaptionWrap---SjnV";
export var linkLargeIcon = "index-thumbnail-module--linkLargeIcon--WTojz";
export var linkLargeIconTuMu = "index-thumbnail-module--linkLargeIconTuMu--vYUhb";
export var linkSmallIcon = "index-thumbnail-module--linkSmallIcon--qvcEc";
export var thumbnail = "index-thumbnail-module--thumbnail--B8w+z";
export var thumbnailImg = "index-thumbnail-module--thumbnailImg--2cIj0";
export var thumbnailImgWrap = "index-thumbnail-module--thumbnailImgWrap--+ggJF";
export var thumbnailList = "index-thumbnail-module--thumbnailList--9qeTB";