import React, {useEffect, useRef} from "react";
// import classNames from "classnames";

import {} from './carousel.module.css';
// import IconAngle from "./widgets/IconAngle";
import LoopAnimations from "../lib/loop-animations";

import carousel_1 from '../images/carousel-1.jpg';
import carousel_2 from '../images/carousel-2.jpg';
import carousel_3 from '../images/carousel-3.jpg';


export default function Carousel({className}) {
    const img1 = useRef(null);
    const img2 = useRef(null);
    const img3 = useRef(null);

    useEffect(() => {
        const keyframes = [
            {marginLeft: 0},
            {marginLeft: '-100%'}
        ];

        const timing = {
            duration: 3000,
            iterations: 1,
            fill: 'forwards',
            delay: 5000,
        };

        const kf1 = new KeyframeEffect(img1.current, keyframes, timing);
        const a1 = new Animation(kf1, document.timeline);

        const kf2 = new KeyframeEffect(img2.current, keyframes, timing);
        const a2 = new Animation(kf2, document.timeline);

        new LoopAnimations([a1, a2], 5000).run();

    }, [])

    return (
        <div style={{display: 'flex', overflow: 'hidden'}} className={className}>
            <div ref={img1} style={{width: '100%', flex: 'none', position: 'relative'}}>
                <img src={carousel_1} style={{width: '100%'}} alt='.'/>
                <div className={'cover'}></div>
                <div style={{position: 'absolute', left: '20%', top: '33%'}}>
                    <h5 style={{color: 'rgb(0,176,236)', fontSize: 'medium'}}>基于工程项目大数据建设</h5>
                    <h2 style={{color: 'white',fontFamily:'粗体', fontSize: 'xx-large', lineHeight: '2em'}}>BIM全过程<span
                        style={{color: 'rgb(0,176,236)'}}>咨询服务</span></h2>
                    <h6 style={{
                        color: 'white',
                        fontSize: 'medium',
                        fontWeight: 'lighter'
                    }}>建设领域BIM及智能信息技术的产品与服务</h6>
                </div>
            </div>
            <div ref={img2} style={{width: '100%', flex: 'none', position: 'relative'}}>
                <img src={carousel_2} style={{width: '100%'}} alt='.'/>
                <div className={'cover'}></div>
                <div style={{position: 'absolute', left: '20%', top: '33%'}}>
                    <h5 style={{color: 'rgb(0,176,236)', fontSize: 'medium'}}>服务于大数据建设与BIM咨询服务</h5>
                    <h2 style={{color: 'white',fontFamily:'粗体', fontSize: 'xx-large', lineHeight: '2em'}}>工程项目<span
                        style={{color: 'rgb(0,176,236)'}}>管理平台</span></h2>
                    <h6 style={{
                        color: 'white',
                        fontSize: 'medium',
                        fontWeight: 'lighter'
                    }}>建设领域BIM及智能信息技术的产品与服务</h6>
                </div>
            </div>
            <div ref={img3} style={{width: '100%', flex: 'none', position: 'relative'}}>
                <img src={carousel_3} style={{width: '100%'}} alt='.'/>
                <div className={'cover'}></div>
                <div style={{position: 'absolute', left: '20%', top: '33%'}}>
                    <h5 style={{color: 'rgb(0,176,236)', fontSize: 'medium'}}>我们一直致力于</h5>
                    <h2 style={{color: 'white',fontFamily:'粗体', fontSize: 'xx-large', lineHeight: '2em'}}>打造海西工程项目<span
                        style={{color: 'rgb(0,176,236)'}}>大数据中心</span></h2>
                    <h6 style={{
                        color: 'white',
                        fontSize: 'medium',
                        fontWeight: 'lighter'
                    }}>建设领域BIM及智能信息技术的产品与服务</h6>
                </div>
            </div>
        </div>
    );

}