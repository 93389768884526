import React from "react";
import classNames from "classnames";

export default function IconCooperationBusiness({color = 'gray', size = '1em', className = ''}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             className={classNames(className)}
             style={{width: size, height: `calc((12.1591/11.5266 * ${size})`}}
             viewBox="0 0 63.75 67.25">
            <g>
                <path fill={color} d="M36.33 1.84c-2.46,-2.46 -6.45,-2.46 -8.9,0 -2.46,2.46 -2.46,6.45 0,8.9 2.46,2.46 6.45,2.46 8.9,0 2.46,-2.46 2.46,-6.45 0,-8.9zm-4.45 65.41c8.59,0 16.39,-3.4 22.12,-8.92 -1.19,-0.43 -2.31,-1.13 -3.27,-2.08 -0.06,-0.06 -0.12,-0.12 -0.18,-0.19 -4.94,4.46 -11.49,7.18 -18.67,7.18 -7.18,0 -13.73,-2.72 -18.67,-7.18 -0.93,0.99 -2.1,1.78 -3.45,2.27 5.73,5.53 13.53,8.92 22.12,8.92zm-27.25 -26.05c-0.4,-1.88 -0.61,-3.83 -0.61,-5.83 0,-12.4 8.09,-22.9 19.29,-26.52 -0.4,-1.34 -0.48,-2.75 -0.24,-4.12 -13.32,3.82 -23.06,16.09 -23.06,30.64 0,2.65 0.33,5.22 0.93,7.68 0.97,-0.82 2.13,-1.44 3.44,-1.79 0.08,-0.02 0.16,-0.04 0.25,-0.06zm35.84 -32.35c11.19,3.62 19.29,14.12 19.29,26.52 0,2 -0.21,3.95 -0.61,5.83 1.32,0.31 2.59,0.93 3.69,1.86 0.61,-2.46 0.93,-5.03 0.93,-7.68 0,-14.55 -9.74,-26.82 -23.06,-30.64 0.24,1.37 0.16,2.78 -0.24,4.12zm21.05 36.6c-2.46,-2.46 -6.45,-2.46 -8.91,-0 -2.46,2.46 -2.46,6.45 0,8.9 2.46,2.46 6.45,2.46 8.91,0 2.46,-2.46 2.46,-6.45 0,-8.9zm-53.19 10.53c3.36,-0.9 5.35,-4.35 4.45,-7.71 -0.9,-3.36 -4.35,-5.35 -7.71,-4.45 -3.36,0.9 -5.35,4.35 -4.45,7.71 0.9,3.36 4.35,5.35 7.71,4.45z"/> </g>
        </svg>)
}