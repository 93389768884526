import React from "react";
import classNames from "classnames";

export default function IconFlowArrow({color = 'gray', height = '5px', className = ''}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             className={classNames(className)}
             style={{height: height, width: `calc((32.08/2.249 * ${height})`}}
             viewBox="0 0 694.98 48.72">
            <g>
                <polygon fill={color}
                         points="595.87,20.17 121.78,20.17 121.78,31 594.84,31 559.58,48.72 649.42,48.72 694.98,25.59 649.42,0 559.58,0 "/>
                <polygon fill={color}
                         points="36.29,20.17 58.01,20.17 58.01,31 35.26,31 -0,48.72 89.84,48.72 135.4,25.59 89.84,0 -0,0 "/>
            </g>
        </svg>)
}