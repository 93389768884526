import React, { useState } from "react";
import classNames from "classnames";

import MainMenu from "../components/main-menu";
import MainFrame from "../components/main-frame";
import Carousel from "../components/carousel";
//#region cssRely
import '../style/normalize.css';
import '../style/html-clear-style.css';
import '../style/common.css';

import {
    mainContent,
    mainContentSection,
    mainMenu,
    subhead,
    subheadItem,
    pageHeader,
    contentTitle,
    contentText,
    mainContentTitleHorizontalLine,
    horizontalLine,
    verticalLine,
    businessDescribeVerticalLine,
    horizontalLineGray,
    businessDescribe,
    businessDescribeHeader,
    businessDescribeContent,
    businessDescribeWrap,
    flowNode__icon,
    flowNode__text,
    flowNode,
    flowNodes
} from './index.module.css';

import {
    cover,
    thumbnailList,
    thumbnail,
    thumbnailImgWrap,
    thumbnailImg,
    linkLargeIcon,
    linkSmallIcon,
    figureCaption,
    figureCaptionWrap,
    figureCaptionLevel1,
    figureCaptionLevel2,
    figureCaptionLevel3
} from './index.thumbnail.module.css';

import {
    partners,
    partner,
    partnerTieJian,
    partnerChentou,
    partnerChentouTech,
    partnerZuohai,
    partnerFuda,
    partnerFuzhou,
    partnerFuzhouchentou,
    partnerHaixia,
    partnerHongkon,
    partnerJiangxi,
    partnerJianzhu,
    // partnerTiejan,
    partnerTongji,
    partnerTsinghua,
    partnerTumu,
    partnerXinjiang,
    partnerZhongjiao,
    partnerZhongye,
    partnerChenxiang,
    partnerZhongnan, partnerZhongtie
} from './index.partners.module.css';
//#endregion
import { coreMembers } from './index.core-member.module.css';

import IconCooperation from "../components/widgets/icon-cooperation";
import IconManage from "../components/widgets/icon-manage";
import IconCooperationBusiness from "../components/widgets/icon-cooperation-business";
import IconFlowArrow from "../components/widgets/icon-flow-arrow";
import IconLink from "../components/widgets/icon-link";
import IconLinkLarge from "../components/widgets/icon-link-large";
import wy from '../images/core-member/wangying.png';
import zyq from '../images/core-member/zyq.png';
import lp from '../images/core-member/lp.png';

const Home = () => {
   const [num,setNum]=useState(0)
    return (
        <MainFrame>
            <header className={pageHeader}>
                <MainMenu className={mainMenu} />
                <Carousel />
                <ul className={subhead}>
                    <li className={subheadItem}>整合资源</li>
                    <li className={subheadItem}>协同共享</li>
                    <li className={subheadItem}>深化应用</li>
                    <li className={subheadItem}>创新驱动</li>
                </ul>
            </header>
            <main className={mainContent}>
                <section className={mainContentSection}>
                    <h2 className={contentTitle}>服务与产品 / Service & Products</h2>
                    <span className={classNames(horizontalLine, mainContentTitleHorizontalLine)}></span>
                    <p className={contentText}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                    <div className={businessDescribeWrap}>
                        <div className={businessDescribe}>
                            <h4 className={businessDescribeHeader}>BIM全过程咨询</h4>
                            <ul className={businessDescribeContent}>
                                <li>从根本上解决工程项目规划、设计、施工、维护管理各阶段的信息断层问题，实现全过程的工程信息化管理；</li>
                                <li>助力建设领域的信息产业化进程。</li>
                            </ul>
                        </div>
                        <span className={classNames(verticalLine, businessDescribeVerticalLine)}></span>
                        <div className={businessDescribe}>
                            <h4 className={businessDescribeHeader}>工程项目管理平台</h4>
                            <ul className={businessDescribeContent}>
                                <li>以BIM标准化建模为基础，固化了管理流程，实现无纸化办公；</li>
                                <li>打破空间与时间的限制，实现多合作单位在建项目之间的高效协同；</li>
                                <li>运用信息化的思维，可促进管理的升级，支撑企业与项目的快速发展。</li>
                            </ul>
                        </div>
                        <span className={classNames(verticalLine, businessDescribeVerticalLine)}></span>
                        <div className={businessDescribe}>
                            <h4 className={businessDescribeHeader}>工程项目大数据建设</h4>
                            <ul className={businessDescribeContent}>
                                <li>从根本上解决工程项目规划、设计、施工、维护管理各阶段的信息断层问题，实现全过程的工程信息化管理；</li>
                                <li>为大数据中心的建设及智慧城市建设奠定坚实的基础。</li>
                            </ul>
                        </div>
                    </div>
                    <ul className={flowNodes}>
                        <li className={flowNode}>
                            <IconCooperation className={flowNode__icon} color={'gray'} size={'3em'} />
                            <span className={flowNode__text}>协同工作</span>
                        </li>
                        <li>
                            <IconFlowArrow height={'.5em'} />
                        </li>
                        <li className={flowNode}>
                            <IconManage className={flowNode__icon} color={'gray'} size={'3em'} />
                            <span className={flowNode__text}>协同管理</span>
                        </li>
                        <li>
                            <IconFlowArrow height={'.5em'} />
                        </li>
                        <li className={flowNode}>
                            <IconCooperationBusiness className={flowNode__icon} color={'gray'} size={'3em'} />
                            <span className={flowNode__text}>协同运营</span>
                        </li>
                    </ul>
                </section>
                <section className={mainContentSection}>
                    <h2 className={contentTitle}>项目案例 / Project Case</h2>
                    <span className={classNames(horizontalLine, mainContentTitleHorizontalLine)}></span>
                    <p className={contentText}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                    <ul className={thumbnailList}>
                        <li className={thumbnail} onMouseOver={()=>{ setNum(1)}} onMouseOut={()=>{setNum(0)}}> 
                            <p className={thumbnailImgWrap}>
                                <img alt={'拉各斯火车站站房'} className={thumbnailImg} src='/image1.jpg' />

                                <a target={"_blank"} rel="noopener noreferrer"
                                    href={'https://mp.weixin.qq.com/s/iVYUg4FC3mywSR6OtXfTwA'}
                                    className={classNames(linkLargeIcon)}>
                                    <IconLinkLarge num={num} count={1}  size={'8vw'} imagePath={'/logo-tumu.png'} />
                                </a>
                                <div className={cover}></div>
                            </p>
                            <header className={figureCaptionWrap}>
                                <h4 className={classNames(figureCaption, figureCaptionLevel1)}>项目名称：拉各斯火车站站房</h4>
                                <h5 className={classNames(figureCaption, figureCaptionLevel2)}>位置：NIGERIA
                                    LAGOS（尼日利亚，拉各斯）</h5>
                                <h6 className={classNames(figureCaption, figureCaptionLevel3)}>类型：轨道交通及建筑</h6>
                                <a target={"_blank"} rel="noopener noreferrer" className={linkSmallIcon}
                                    href={'https://mp.weixin.qq.com/s/iVYUg4FC3mywSR6OtXfTwA'}>
                                    <IconLink num={num} count={1}  size={'1.2em'} />
                                </a>
                            </header>
                        </li>
                        <li className={thumbnail} onMouseOver={()=>{ setNum(2)}} onMouseOut={()=>{setNum(0)}}>
                            <p className={thumbnailImgWrap}>
                                <img alt={'厦门地铁六号线'} className={thumbnailImg} src='/image2.jpg' />
                                <a target={"_blank"} rel="noopener noreferrer"
                                    href={'https://mp.weixin.qq.com/s/HWg7iVn2twHLFA8N_zVqhA'}
                                    className={classNames(linkLargeIcon)}>
                                    <IconLinkLarge num={num} count={2} size={'8vw'} imagePath={'/logo-tiejian.png'} />
                                </a>
                                <div className={cover}></div>
                            </p>
                            <header className={figureCaptionWrap}>
                                <h4 className={classNames(figureCaption, figureCaptionLevel1)}>项目名称：厦门地铁六号线</h4>
                                <h5 className={classNames(figureCaption, figureCaptionLevel2)}>位置：福建厦门</h5>
                                <h6 className={classNames(figureCaption, figureCaptionLevel3)}>类型：高速道路 /
                                    桥梁隧道</h6>
                                <a target={"_blank"} rel="noopener noreferrer" className={linkSmallIcon}
                                    href={'https://mp.weixin.qq.com/s/HWg7iVn2twHLFA8N_zVqhA'}>
                                    <IconLink num={num} count={2} size={'1.2em'} />
                                </a>
                            </header>
                        </li>
                        <li className={thumbnail} onMouseOver={()=>{ setNum(3)}} onMouseOut={()=>{setNum(0)}}>
                            <p className={thumbnailImgWrap}>
                                <img alt={'郑州地铁12号线'} className={thumbnailImg} src='/image3.jpg' />
                                <a target={"_blank"} rel="noopener noreferrer"
                                    href={'https://mp.weixin.qq.com/s/e4Gd0uSgf9VQjpNOVSp9Yw'}
                                    className={classNames(linkLargeIcon)}>
                                    <IconLinkLarge num={num} count={3}  size={'8vw'} imagePath={'/logo-tiejian.png'} />
                                </a>
                                <div className={cover}></div>
                            </p>
                            <header className={figureCaptionWrap}>
                                <h4 className={classNames(figureCaption, figureCaptionLevel1)}>项目名称：郑州地铁12号线</h4>
                                <h5 className={classNames(figureCaption, figureCaptionLevel2)}>位置：河南郑州</h5>
                                <h6 className={classNames(figureCaption, figureCaptionLevel3)}>类型：轨道交通及建筑</h6>
                                <a target={"_blank"} rel="noopener noreferrer" className={linkSmallIcon}
                                    href={'https://mp.weixin.qq.com/s/e4Gd0uSgf9VQjpNOVSp9Yw'}>
                                    <IconLink num={num} count={3}   size={'1.2em'} />
                                </a>
                            </header>
                        </li>
                        <li className={thumbnail} onMouseOver={()=>{ setNum(4)}} onMouseOut={()=>{setNum(0)}}>
                            <p className={thumbnailImgWrap}>
                                <img alt={'重庆至黔江铁路站前9标桥梁工程'} className={thumbnailImg} src='/image4.jpg' />
                                <a target={"_blank"} rel="noopener noreferrer"
                                    href={'https://mp.weixin.qq.com/s/KqleHoiCyoQ69ruZ32hc8Q'}
                                    className={classNames(linkLargeIcon)}>
                                    <IconLinkLarge num={num} count={4}  size={'8vw'} imagePath={'/logo-zhongtie.png'} />
                                </a>
                                <div className={cover}></div>
                            </p>
                            <header className={figureCaptionWrap}>
                                <h4 className={classNames(figureCaption, figureCaptionLevel1)}>项目名称：重庆沙坪坝城市大型交通枢纽</h4>
                                <h5 className={classNames(figureCaption, figureCaptionLevel2)}>位置：重庆</h5>
                                <h6 className={classNames(figureCaption, figureCaptionLevel3)}>类型：桥梁</h6>
                                <a target={"_blank"} rel="noopener noreferrer" className={linkSmallIcon}
                                    href={'https://mp.weixin.qq.com/s/KqleHoiCyoQ69ruZ32hc8Q'}>
                                    <IconLink num={num} count={4}   size={'1.2em'} />
                                </a>
                            </header>
                        </li>
                        <li className={thumbnail} onMouseOver={()=>{ setNum(5)}} onMouseOut={()=>{setNum(0)}}>
                            <p className={thumbnailImgWrap}>
                                <img alt={'福州鼓山马尾隧道提升改造工程'} className={thumbnailImg} src='/image5.jpg' />
                                <a target={"_blank"} rel="noopener noreferrer"
                                    href={'https://mp.weixin.qq.com/s/Km14RnT5GjHEuDD0awATdg'}
                                    className={classNames(linkLargeIcon)}>
                                    <IconLinkLarge num={num} count={5}  size={'8vw'} imagePath={'/logo-jianzong.png'} />
                                </a>
                                <div className={cover}></div>
                            </p>
                            <header className={figureCaptionWrap}>
                                <h4 className={classNames(figureCaption, figureCaptionLevel1)}>项目名称：福州鼓山马尾隧道提升改造工程</h4>
                                <h5 className={classNames(figureCaption, figureCaptionLevel2)}>位置：福建福州</h5>
                                <h6 className={classNames(figureCaption, figureCaptionLevel3)}>类型：隧道</h6>
                                <a target={"_blank"} rel="noopener noreferrer" className={linkSmallIcon}
                                    href={'https://mp.weixin.qq.com/s/Km14RnT5GjHEuDD0awATdg'}>
                                    <IconLink num={num} count={5}   size={'1.2em'} />
                                </a>
                            </header>
                        </li>
                        <li className={thumbnail} onMouseOver={()=>{ setNum(6)}} onMouseOut={()=>{setNum(0)}}>
                            <p className={thumbnailImgWrap}>
                                <img alt={'郑州地铁12号线'} className={thumbnailImg} src='/image6.jpg' />
                                <a target={"_blank"} rel="noopener noreferrer"
                                    href={'https://mp.weixin.qq.com/s/2y2T-TQdXvB0KornbOShig'}
                                    className={classNames(linkLargeIcon)}>
                                    <IconLinkLarge num={num} count={6}  size={'8vw'} imagePath={'/logo-zhongtie.png'} />
                                </a>
                                <div className={cover}></div>
                            </p>
                            <header className={figureCaptionWrap}>
                                <h4 className={classNames(figureCaption, figureCaptionLevel1)}>项目名称：贵州都安高速公路</h4>
                                <h5 className={classNames(figureCaption, figureCaptionLevel2)}>位置：贵州</h5>
                                <h6 className={classNames(figureCaption, figureCaptionLevel3)}>类型：高速公路</h6>
                                <a target={"_blank"} rel="noopener noreferrer" className={linkSmallIcon}
                                    href={'https://mp.weixin.qq.com/s/2y2T-TQdXvB0KornbOShig'}>
                                    <IconLink  num={num} count={6}   size={'1.2em'} />
                                </a>
                            </header>
                        </li>
                    </ul>
                </section>
                <section className={classNames(mainContentSection)} style={{ position: 'relative', marginTop: '8em' }}>
                    <div className={'cover'}></div>
                    <div style={{
                        position: 'absolute', display: 'flex', alignItems: 'center', flexFlow: 'column nowrap'
                    }}>
                        <h2 className={contentTitle} style={{ color: 'rgb(0,255,255)', marginTop: '1em' }}>功能体验 /
                            Functional
                            experience</h2>
                        <span className={classNames(horizontalLine, mainContentTitleHorizontalLine, horizontalLineGray)}
                            style={{ width: '60em' }}></span>
                        <p className={contentText} style={{
                            color: 'rgb(200,200,200)', margin: '1em 0'
                        }}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                        <h2 style={{ color: 'white', fontSize: 'xxx-large', fontWeight: 'normal' }}>轻量化模型互动</h2>
                    </div>
                    <img alt={'轻量化模型互动'} style={{ width: '100%' }} src={'/3dmodel.jpg'} />
                    <a target={"_blank"} rel="noopener noreferrer" onMouseOver={()=>{setNum(7)}} onMouseOut={()=>{setNum(0)}}
                        href={'http://qyjbim.com/bim'}
                        className={classNames(linkLargeIcon)}>
                        <IconLinkLarge num={num} count={7} size={'12vw'} imagePath={'/3dmodel-link-icon.png'} />
                    </a>
                </section>
                <section className={classNames(mainContentSection)} style={{ position: 'relative', marginTop: '0' }}>
                    <div className={'cover'}></div>
                    <div style={{
                        position: 'absolute', display: 'flex', alignItems: 'center', flexFlow: 'column nowrap'
                    }}>
                        <p className={contentText}
                            style={{ color: 'rgb(160,217,246)', margin: '4em 0 1em 0' }}>功能体验</p>
                        <h2 style={{ color: 'white', fontSize: 'xxx-large', fontWeight: 'normal' }}>720-VR全景图</h2>
                    </div>
                    <img alt={'720vr全景图'} style={{ width: '100%' }} src={'/720vr.jpg'} />
                    <a target={"_blank"} rel="noopener noreferrer" 
                         href={'https://6dbm287blkr.720yun.com/t/81vkt7dlgfm?scene_id=73669094' }
                         onMouseOver={()=>{setNum(8)}} onMouseOut={()=>{setNum(0)}}  className={classNames(linkLargeIcon)} >
                        <IconLinkLarge num={num} count={8} size={'12vw'} imagePath={'/720vr-link-icon.png'} />
                    </a>
                </section>
                <section className={mainContentSection}>
                    <h2 className={contentTitle}>主要合作伙伴 / Main partners</h2>
                    <span className={classNames(horizontalLine, mainContentTitleHorizontalLine)}></span>
                    <p className={contentText}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                    <table className={partners}>
                        <tbody>
                            <tr>
                                <td className={classNames(partner, partnerTieJian)}></td>
                                <td className={classNames(partner, partnerZhongtie)}></td>
                                <td className={classNames(partner, partnerTumu)}></td>
                                <td className={classNames(partner, partnerZhongye)}></td>
                                <td className={classNames(partner, partnerZhongjiao)}></td>
                            </tr>
                            <tr>
                                <td className={classNames(partner, partnerJianzhu)}></td>
                                <td className={classNames(partner, partnerFuzhou)}></td>
                                <td className={classNames(partner, partnerXinjiang)}></td>
                                <td className={classNames(partner, partnerHaixia)}></td>
                                <td className={classNames(partner, partnerFuzhouchentou)}></td>
                            </tr>
                            <tr>
                                <td className={classNames(partner, partnerZuohai)}></td>
                                <td className={classNames(partner, partnerChenxiang)}></td>
                                <td className={classNames(partner, partnerChentou)}></td>
                                <td className={classNames(partner, partnerChentouTech)}></td>
                                <td className={classNames(partner, partnerJiangxi)}></td>
                            </tr>
                            <tr>
                                <td className={classNames(partner, partnerTsinghua)}></td>
                                <td className={classNames(partner, partnerTongji)}></td>
                                <td className={classNames(partner, partnerZhongnan)}></td>
                                <td className={classNames(partner, partnerFuda)}></td>
                                <td className={classNames(partner, partnerHongkon)}></td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'center', fontSize: 'x-small', padding: '1em' }}>
                        期待您的加入...
                    </div>
                </section>
                <section className={classNames(mainContentSection)} style={{ backgroundColor: ' rgb(240,240,240)', marginTop: '4em' }}>
                    <h2 className={contentTitle}>我们的核心团队 / Core Team</h2>
                    <span className={classNames(horizontalLine, mainContentTitleHorizontalLine)}></span>
                    <p className={contentText}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                    <ul className={coreMembers}>
                        <li style={{ margin: '1em' }}><img style={{ width: '15vw' }} alt={'wangying'} src={wy} /></li>
                        <li style={{ margin: '1em' }}><img style={{ width: '15vw' }} alt={'wangying'} src={zyq} /></li>
                        <li style={{ margin: '1em' }}><img style={{ width: '15vw' }} alt={'wangying'} src={lp} /></li>
                    </ul>
                </section>
            </main>
        </MainFrame>
    )
}


export default Home;